<template>
  <div>
    <slot name="top"/>
    <el-table
        v-loading="loading"
        ref="table"
        :emptyText="emptyText"
        :data="filter?data.data.filter(filter):data.data"
        :stripe="true"
        :border="true"
        :highlight-current-row="true"
        @select="select"
        @select-all="selectAll"
        max-height="550"
        style="width: 100%;padding: 5px;">
      <slot/>
    </el-table>
    <template v-if="enablePage">
      <el-pagination
          background
          :hide-on-single-page="true"
          :page-sizes="[10,20,30,40,50, 100, 200, 300, 400]"
          :page-size="limit"
          @size-change="sizeChange"
          v-model:currentPage="page"
          layout="prev, pager, next , total , sizes , jumper"
          :total="count"/>
    </template>
  </div>
</template>

<script>
import qs from "qs";

export default {
  name: "Table",
  props: {
    filter:{
      type:Function,
      default:null
    },
    pageSize:{
      type:Number,
      default:50
    },
    enablePage: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: null
    },
    param: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      emptyText: '',
      loading: false,
      data: {
        data:[]
      },
      count: 10,
      page: 1,
      limit: this.pageSize,
      selection: []
    }
  },
  created() {
    console.log("created")
    this.query();
  },
  watch: {
    page(old, nv) {
      console.log("page change", old, nv)
      this.query()
    },
    url: function (val) {
      console.log(val)
      console.log("watch:url")
      this.query();
    },
    param: {
      deep: true,
      handler(o,n) {
        console.log("watch:param",this.param)
        console.log(o,n)
        this.page = 1;
        this.query();
      }
    }
  },
  methods: {
    select(selection) {
      this.selection = selection;
    },
    selectAll(selection) {
      this.selection = selection;
    },
    sizeChange(size) {
      console.log("sizeChange", size)
      this.limit = size;
      if (this.page * size <= this.data.count) {
        this.query();
      }
    },
    currentChange(page) {
      console.log("currentChange", page)
      if (this.page !== page) {
        this.page = page;
        this.query();
      }
    },
    reload(){
      this.query();
    },
    query() {
      const that = this;
      if (this.url) {
        let param = this.param ? {...this.param} : {};
        param.page = this.page;
        param.limit = this.limit;
        this.loading = true;
        this.axios.post(this.url, qs.stringify(param))
            .then((res) => {
              console.log(res);
              if (res.data.code === 0) {
                that.data = res.data;
                if (res.data.count && res.data.count > 0) {
                  that.count = res.data.count;
                }
                that.selection = []
                this.emptyText = '暂无数据';
              } else {
                that.data = []
                this.emptyText = res.data.msg;
              }
              this.loading = false;
            }).catch(err => {
          console.log(err);
          this.loading = false
        })
      }
    },
    deleteByIndex(index) {
      this.data.data.splice(index, 1);
    },
    getData() {
      return this.data.data
    }
  }
}
</script>

<style scoped>

</style>
