import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/Dashboard.vue")
            }, {
                path: "/system/staff.html",
                name: "SystemStaff",
                meta: {
                    title: '账户管理-系统级别'
                },
                component: () => import (  "../views/system/Staff/Staff.vue")
            }, {
                path: "/staff/index.html",
                name: "Staff",
                meta: {
                    title: '员工管理'
                },
                component: () => import (  "../views/teljob/Staff/Staff.vue")
            }, {
                path: "/user/index.html",
                name: "User",
                meta: {
                    title: '账户管理'
                },
                component: () => import (  "../views/User/index.vue")
            }, {
                path: "/organization/index.html",
                name: "Organization",
                meta: {
                    title: '组织架构'
                },
                component: () => import (  "../views/OrganizationIndex.vue")
            }, {
                path: "/authStaffOrg/index.html",
                name: "AuthStaffOrg",
                meta: {
                    title: '组织授权'
                },
                component: () => import (  "../views/AuthStaffOrgIndex.vue")
            }, {
                path: "/role/index.html",
                name: "Role",
                meta: {
                    title: '角色管理-电销级别'
                },
                component: () => import (  "../views/teljob/Role/Role.vue")
            }, {
                path: "/roleStaff/index.html",
                name: "RoleStaff",
                meta: {
                    title: '授权管理'
                },
                component: () => import (  "../views/RoleStaff.vue")
            }, {
                path: "/customer/my.html",
                name: "MyCustomer",
                meta: {
                    title: '我的客户'
                },
                component: () => import (  "../views/customer/MyCustomer.vue")
            }, {
                path: "/customer/import.html",
                name: "ImportCustomer",
                meta: {
                    title: '导入客户'
                },
                component: () => import (  "../views/customer/import/ImportCustomer.vue")
            }, {
                path: "/customer/distribute.html",
                name: "DistributeCustomer",
                meta: {
                    title: '分配客户'
                },
                component: () => import (  "../views/customer/DistributeCustomer.vue")
            }, {
                path: "/customer/public.html",
                name: "PublicCustomer",
                meta: {
                    title: '公共池'
                },
                component: () => import (  "../views/customer/PublicCustomerAll.vue")
            }, {
                path: "/customer/publicIntention.html",
                name: "PublicCustomerIntention",
                meta: {
                    title: '公共池-意向',
                    intention: true
                },
                component: () => import ("../views/customer/PublicCustomerIntention.vue")
            }, {
                path: "/customer/all.html",
                name: "AllCustomer",
                meta: {
                    title: '全部客户'
                },
                component: () => import (  "../views/customer/AllCustomer.vue")
            }, {
                path: "/customer/order.html",
                name: "OrderCustomer",
                meta: {
                    title: '已成单'
                },
                component: () => import (  "../views/customer/OrderCustomer.vue")
            }, {
                path: "/customer/tag.html",
                name: "TagCustomer",
                meta: {
                    title: '标记客户'
                },
                component: () => import (  "../views/customer/TagCustomer.vue")
            }, {
                path: "/customer/disable.html",
                name: "DisableCustomer",
                meta: {
                    title: '回收站'
                },
                component: () => import (  "../views/customer/DisableCustomer.vue")
            }, {
                path: "/intention/staff.html",
                name: "CountByStaff",
                meta: {
                    title: '员工意向统计'
                },
                component: () => import (  "../views/intentionCount/CountByStaff.vue")
            }, {
                path: "/intention/customer.html",
                name: "CustomerCountGroup.vue",
                meta: {
                    title: '客户意向统计'
                },
                component: () => import (  "../views/count/CountGroup.vue")
            }, {
                path: "/customer/org.html1",
                name: "CustomerCount",
                meta: {
                    title: '客户统计'
                },
                component: () => import (  "../views/count/CustomerCount.vue")
            }, {
                path: "/customerDocTmp/index.html",
                name: "CustomerDocTmp",
                meta: {
                    title: '客户跟单'
                },
                component: () => import (  "../views/customerDoc/customerDocTmp.vue")
            }, {
                path: "/intentionTmp/index.html",
                name: "IntentionTmp",
                meta: {
                    title: '意向配置'
                },
                component: () => import (  "../views/intention/intentionTmp.vue")
            }, {
                path: "/setting/index.html",
                name: "Setting",
                meta: {
                    title: '基础设置'
                },
                component: () => import (  "../views/setting/Setting.vue")
            }, {
                path: "/form/index.html",
                name: "Form",
                meta: {
                    title: '表单'
                },
                component: () => import (  "../views/form/Form.vue")
            }, {
                path: "/formTemplate/index.html",
                name: "FormTemplate",
                meta: {
                    title: '表单模板'
                },
                component: () => import (  "../views/formTemplate/index.vue")
            }, {
                path: "/checkConfig/index.html",
                name: "CheckConfig",
                meta: {
                    title: '考核配置'
                },
                component: () => import (  "../views/checkConfig/CheckConfig.vue")
            }, {
                path: "/count/index.html",
                name: "RecordCount",
                meta: {
                    title: '通话统计'
                },
                component: () => import (  "../views/count/Record.vue")
            }, {
                path: "/checkCount/index.html",
                name: "CheckCount",
                meta: {
                    title: '考核统计'
                },
                component: () => import (  "../views/count/CheckCount.vue")
            }, {
                path: "/customer/org.html",
                name: "OrgCustomer",
                meta: {
                    title: '客户统计'
                },
                component: () => import (  "../views/customer/OrgCustomer.vue")
            }, {
                path: "/system/page.html",
                name: "SystemPage",
                meta: {
                    title: '页面设置'
                },
                component: () => import (  "../views/system/Page/Page.vue")
            }, {
                path: "/system/auth.html",
                name: "SystemAuth",
                meta: {
                    title: '权限设置'
                },
                component: () => import (  "../views/system/Auth/Auth.vue")
            }, {
                path: "/system/role.html",
                name: "SystemRole",
                meta: {
                    title: '角色设置'
                },
                component: () => import (  "../views/system/Role/Role.vue")
            }, {
                path: "/risk/organization.html",
                name: "riskOrganization",
                meta: {
                    title: '风控组织管理'
                },
                component: () => import (  "../views/risk/Organization/Organization.vue")
            }, {
                path: "/risk/customer.html",
                name: "riskCustomer",
                meta: {
                    title: '客户订单'
                },
                component: () => import (  "../views/risk/customer/customer.vue")
            }, {
                path: "/logistics/projectTmp.html",
                name: "logisticsProjectTmp",
                meta: {
                    title: '客户模板管理'
                },
                component: () => import (  "@/views/logistics/ProjectTmp/ProjectTmp.vue")
                //    component:()=>import("@/views/customerDoc/customerDocTmp.vue")
            }, {
                path: "/logistics/organization.html",
                name: "logisticsOrganization",
                meta: {
                    title: '后勤组织管理'
                },
                component: () => import (  "../views/logistics/Organization/Organization.vue")
            }, {
                path: "/logistics/archives.html",
                name: "logisticsArchives",
                meta: {
                    title: '客户档案管理'
                },
                component: () => import (  "../views/logistics/archives/index/index.vue")
            }, {
                path: "/logistics/order.html",
                name: "logisticsOrder",
                meta: {
                    title: '做单客户'
                },
                component: () => import (  "../views/logistics/order/index.vue")
            }, {
                path: "/serve/product/index.html",
                name: "serveProduct",
                meta: {
                    title: '产品推荐'
                },
                component: () => import (  "../views/serve/product/index.vue")
            }, {
                path: "/account/association.html",
                name: "accountAssociation",
                meta: {
                    title: '账户关联'
                },
                component: () => import (  "../views/account/Association.vue")
            }, {
                path: "/after/customer/index.html",
                name: "afterCustomer",
                meta: {
                    title: '客户管理'
                },
                component: () => import (  "../views/after/customer/index.vue")
            }, {
                path: "/wechat/userInfo.html",
                name: "wechatUserInfo",
                meta: {
                    title: '微信客户管理'
                },
                component: () => import (  "../views/wechat/UserInfo.vue")
            }, {
                path: "/autoShare/index.html",
                name: "autoShareIndex",
                meta: {
                    title: '自动分享配置'
                },
                component: () => import (  "../views/autoShare/AutoShare.vue")
            }, {
                path: "/allocConfig/index.html",
                name: "allocConfigIndex",
                meta: {
                    title: '自动分配配置'
                },
                component: () => import (  "../views/allocConfig/index.vue")
            }, {
                path: "/attendance.html",
                name: "Attendance",
                meta: {
                    title: '考勤管理'
                },
                component: () => import (  "../views/attendance/Attendance.vue")
            }, {
                path: "/reward.html",
                name: "Reward",
                meta: {
                    title: '奖励扣款'
                },
                component: () => import (  "../views/reward/Reward.vue")
            }, {
                path: "/riskOrder.html",
                name: "RiskOrder",
                meta: {
                    title: '客户单'
                },
                component: () => import (  "../views/riskOrder/RiskOrder.vue")
            }, {
                path: "/riskOrderCharge.html",
                name: "RiskOrderCharge",
                meta: {
                    title: '提成列表'
                },
                component: () => import (  "../views/riskOrder/RiskOrderCharge.vue")
            }, {
                path: "/commissionConfig.html",
                name: "CommissionConfig",
                meta: {
                    title: '提成配置'
                },
                component: () => import (  "../views/Commission/CommissionConfig.vue")
            }, {
                path: "/salary/index.html",
                name: "SalaryIndex",
                meta: {
                    title: '薪资表'
                },
                component: () => import (  "../views/salary/index.vue")
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import ("../views/Login/Login.vue")
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | 厦门井雨企业管理咨询`;
    const token = sessionStorage.getItem("token");
    if (!token && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        // role === 'admin'
        //     ? next()
        //     : next('/403');
    } else {
        next();
    }
});

export default router
